import NewHeader from '@interco/institucional-frontend-lib-header/dist'
import Footer from '@interco/inter-frontend-lib-footer/lib/site'
import { TrackData } from '@interco/track'
import { navigate, PageProps } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import articles from 'src/assets/data/faq-babi/articles.json'
import OrangeIcon from 'src/components/Icons'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Hero } from 'src/pages/sections/hero/_index'
import GlobalStyle from 'src/styles/global'
import categoriesJSON from '../../assets/data/faq-babi/categories.json'
import type { ArticlesModel, ArticlesPropsModel } from '../../pages/types'
import { Back, BreadCrumb, Description, Divisor, Feedback, FeedBackButton, FeedbackSUccess, FormContainer, FormTitle, RadiusSelection, Section, SendButtonForm, TextAreaContainer, TextAreaTitle, Title } from './styles'

import { useForm } from 'react-hook-form'
import { Search } from 'src/components/SearchList/_index'
import SEO from 'src/components/Seo'
import { View } from 'src/types/View'
import { normalizeString } from 'src/utils/normalizeString'



type PageContext = {
  item: ArticlesPropsModel;
}

type SelectedTopicProps = {
  pageContext: PageContext;
  runtimeEnv: any;
} & PageProps

enum UsefullReaction {
  DISLIKE = 'dislike',
  LIKE = 'like'
}

enum RadiusFormData {
  INSTRUCTIONSNOTBEEASY = 'As instruções não eram claras',
  LIKEARTICLEBUTNEEDBEBETTER = 'Gostei do artigo, mas o processo pode melhorar',
  NOTRELATIONWITHTOPIC = 'As  informações estão desatualizadas/incorretas',
  OTHER = 'Outra'
}

const Articles = ({ pageContext }: SelectedTopicProps) => {
  const articlesList: ArticlesModel = articles
  const [ view, setView ] = useState(View.Topic)
  const [ selectedTopic, setSelectedTopic ] = useState({} as ArticlesPropsModel)
  const [ submitedSearch, setSubmitedSearch ] = useState('')
  const [ showDislikeForm, setShowDislikeForm ] = useState(false)

  const { register, handleSubmit } = useForm({ defaultValues: { 'selected-option': null, suggestion: '' } })
  const [ sendDatalayerEvent ] = useDataLayer()

  const [usefullReaction, setUsefullReaction] = useState<UsefullReaction | null>(null)

  const findCategory = categoriesJSON.pt.find(item => item.id === pageContext.item.category_id)?.title ?? ''

  let likeArticles: String[] = []
  let dislikeArticles: String[] = []
  const localStorageName = 'articles_userfull_reaction'

  TrackData.initOrUpdate({
    runtimeEnv: 'production',
    projectName: 'ajuda_inter_co',
  })

  

  const getLocalStorageItem = () => {
    let getItem = localStorage.getItem(localStorageName)

    if (!getItem) {
      localStorage.setItem(localStorageName, JSON.stringify({likeArticles: [], dislikeArticles: []}))
    }

    getItem = localStorage.getItem(localStorageName)

    return JSON.parse(getItem!)
  }

  const contentMetas = {
    slug: `/${normalizeString(findCategory)}/${normalizeString(pageContext.item.title)}/`,
    metaTitle: `${pageContext.item.title}`,
    metaDescription: 'A parceria que você precisa pra chegar onde quiser.',
    hideBabiChat: true,
  }

  const handleLikeButton = useCallback(() => {
    handleSaveToLocalStorageByType(pageContext.item.id, UsefullReaction.LIKE)
    TrackData.log({ conteudo: pageContext.item.title, evento: 'like', url: window.location.href, categoria: findCategory })
  }, [])

  const handleDislikeButton = useCallback(() => {
    setShowDislikeForm(true)
  }, [])


  const handleSaveToLocalStorageByType = async (articleId: string, type: UsefullReaction) => {
    if (type === UsefullReaction.LIKE) {
      const findArticle = likeArticles.find(item => item === articleId)
      const checkIfDislikeArticleBefore = dislikeArticles.find(item => item === articleId)

      if (checkIfDislikeArticleBefore) {
        let filteredArticles = dislikeArticles.filter(item => item !== articleId)
        dislikeArticles = filteredArticles
      }

      if (findArticle) {
        setUsefullReaction(UsefullReaction.LIKE)
        return
      }

      likeArticles.push(articleId)
      setUsefullReaction(UsefullReaction.LIKE)
      localStorage.setItem(localStorageName, JSON.stringify({ likeArticles, dislikeArticles }))
      return
    }

    if (type === UsefullReaction.DISLIKE) {
      const findArticle = dislikeArticles.find(item => item === pageContext.item.id)
      const checkIfLikeArticleBefore = likeArticles.find(item => item === pageContext.item.id)

      if (checkIfLikeArticleBefore) {
        let filteredArticle = likeArticles.filter(item => item !== articleId)
        likeArticles = filteredArticle
      }

      if (findArticle) {
        setUsefullReaction(UsefullReaction.DISLIKE)
        return
      }

      dislikeArticles.push(articleId)
      setUsefullReaction(UsefullReaction.DISLIKE)
      localStorage.setItem(localStorageName, JSON.stringify({ likeArticles, dislikeArticles }))
      return
    }
  }


  const submitForm = (data: { 'selected-option': string, suggestion?: string }) => {
    handleSaveToLocalStorageByType(pageContext.item.id, UsefullReaction.DISLIKE)
    TrackData.log({ 
      conteudo: pageContext.item.title, 
      evento: 'unlike', 
      url: window.location.href, 
      categoria: findCategory, 
      melhoria: data['selected-option'], 
      sugestao: data.suggestion || '' 
    })
    console.log(data)
  }

  useEffect(() => {
    const getLocalStorage = getLocalStorageItem()

    likeArticles = getLocalStorage.likeArticles
    dislikeArticles = getLocalStorage.dislikeArticles
    console.log(pageContext.item.id)
  }, [ pageContext ])

  useEffect(() => {
    const checkIfLikeArticleBefore = likeArticles?.find(item => item === pageContext.item.id)
    const checkIfDislikeArticleBefore = dislikeArticles?.find(item => item === pageContext.item.id)

    if (checkIfLikeArticleBefore) {
      return setUsefullReaction(UsefullReaction.LIKE)
    }

    if (checkIfDislikeArticleBefore) {
      return setUsefullReaction(UsefullReaction.DISLIKE)
    }
  }, [likeArticles, dislikeArticles])

  return (
    <>
      <GlobalStyle />
      <SEO
        title={contentMetas.metaTitle}
        description={contentMetas.metaDescription}
        canonical={`${process.env.BASE_URL}${contentMetas.slug}`}
      />
      <NewHeader theme='default'/>
      <main>
          <Hero
            data={articlesList}
            softView={true}
            submitedSearch={submitedSearch}
            setSubmitedSearch={setSubmitedSearch}
            setSelectedTopic={setSelectedTopic}
            view={view}
            setView={setView}
            sendDatalayerEvent={sendDatalayerEvent}
            isHomePage={false}
          />
          {view  === View.Topic && (
            <Section className='container pt-5'>
              <div className='row'>
                <div className='col-12'>
                  <BreadCrumb>
                    <a href='/'>Central de Relacionamento</a>
                    <div>
                      <OrangeIcon size='SM' color='#161616' icon='arrow-right' />
                    </div>
                    <p>{pageContext.item.title}</p>
                  </BreadCrumb>
                  <Divisor />
                  <Back onClick={() => navigate('/')}>
                    <div>
                      <OrangeIcon size='MD' color='#ff7a00' icon='chevron-left' />
                    </div>
                    Voltar
                  </Back>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 mx-lg-auto col-lg-9'>
                  <Title>{pageContext.item.title}</Title>
                  <Description dangerouslySetInnerHTML={{ __html: pageContext.item.content }} />
                  {usefullReaction !== null ? (
                    <FeedbackSUccess>
                      <OrangeIcon size='MD' color='#999' icon={'like'} />
                      <p>Agradecemos pelo feedback!</p>
                    </FeedbackSUccess>

                  ) : (
                    <>
                      <Feedback>
                        <p>Essa resposta foi útil?</p>
                        <div>
                          <FeedBackButton className='icon' onClick={handleLikeButton}>
                            <OrangeIcon size='MD' color='#00AA4F' icon={usefullReaction === UsefullReaction.LIKE ? 'like' :'like-outline'} />
                          </FeedBackButton>
                          <FeedBackButton className='icon' onClick={handleDislikeButton}>
                            <OrangeIcon size='MD' color='#F56A50' icon={showDislikeForm ? 'dislike' :'dislike-outline'} />
                          </FeedBackButton>
                        </div>
                      </Feedback>
                      {showDislikeForm && (
                        <FormContainer onSubmit={handleSubmit(submitForm)}>
                          <FormTitle>O que podemos melhorar?</FormTitle>
                          <RadiusSelection htmlFor={RadiusFormData.INSTRUCTIONSNOTBEEASY}>
                            <input id={RadiusFormData.INSTRUCTIONSNOTBEEASY} type='radio' value={RadiusFormData.INSTRUCTIONSNOTBEEASY} name="selected-option" ref={register} />
                            {RadiusFormData.INSTRUCTIONSNOTBEEASY}
                          </RadiusSelection>
                          <RadiusSelection htmlFor={RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER}>
                            <input id={RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER} type='radio' value={RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER}  name="selected-option" ref={register} />
                            {RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER}
                          </RadiusSelection>
                          <RadiusSelection htmlFor={RadiusFormData.NOTRELATIONWITHTOPIC}>
                            <input id={RadiusFormData.NOTRELATIONWITHTOPIC} type='radio' value={RadiusFormData.NOTRELATIONWITHTOPIC}  name="selected-option" ref={register}/>
                            {RadiusFormData.NOTRELATIONWITHTOPIC}
                          </RadiusSelection>
                          <RadiusSelection htmlFor={RadiusFormData.OTHER}>
                            <input id={RadiusFormData.OTHER} type='radio' value={RadiusFormData.OTHER}  name="selected-option" ref={register}/>
                            Outra
                          </RadiusSelection>
                          <TextAreaContainer>
                            <TextAreaTitle>Diga-nos o que podemos melhorar</TextAreaTitle>
                            <textarea placeholder='(opcional)' name="suggestion" ref={register}/>
                          </TextAreaContainer>
                          <SendButtonForm type='submit'>Enviar</SendButtonForm>
                        </FormContainer>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Section>
          )}
          {view === View.Search && (
            <Search 
              data={articlesList} 
              submitedSearch={submitedSearch} 
              setView={setView} setSelectedTopic={() => {}} />
          )}
      </main>
      <Footer isPT />
    </>
  )
}

export default Articles
