import styled, { css } from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }
`

export const Section = styled.section`
  position: relative;
  padding-top: 16px;
  padding-bottom: 50px;

  @media ${device.tablet} {
    padding-top: 1px;
    padding-bottom: 256px;
  }
`

export const BreadCrumb = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
  }

  align-items: center;
  gap: 16px;
  margin-bottom: 40px;

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: ${grayscale[500]};
    margin-bottom: 0;
  }

  > div {
    display: flex;
    align-items: center;
  }
`

export const Divisor = styled.div`
  display: none;
  border: 1px solid ${grayscale[200]};
  margin-bottom: 21px;

  @media ${device.tablet} {
    display: flex;
  }
`

export const Back = styled.div`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.base};

  margin-bottom: 16px;
  cursor: pointer;
  width: fit-content;

  > div {
    margin-right: 4px;
    display: flex;
    align-items: center;
  }

  @media ${device.tablet} {
    margin-bottom: 52px;
  }
`

export const Title = styled.h1`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${grayscale[500]};

  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
  }
`

export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: ${grayscale[400]};

  margin-bottom: 40px;

  @media ${device.tablet} {
    font-size: 18px;
    margin-bottom: 48px;
  }

  li {
    line-height: 150%;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    color: #8C91A9;
  }
`

export const Feedback = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: ${grayscale[500]};

    margin-bottom: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .icon {
    cursor: pointer;
  }

  @media ${device.tablet} {
    justify-content: flex-start;

    p {
      margin-right: 48px;
    }
  }
`

export const FeedBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  height: 44px;
  width: 44px;
  border-radius: 22px;
  background: none;

  &:focus {
    outline: none;
    border: none;
  }

  &:active {
    /* border: 1px solid green; */
    outline: none;
    border: none;
  }
`

export const FeedbackSUccess = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${grayscale[100]};
  padding: 16px 18px;
  max-width: 340px;
  border-radius: 12px;
  margin-bottom: 44px;

  p {
    font-weight: bold;
    margin-left: 8px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

export const FormContainer = styled.form`
  width: 100%;
  margin-bottom: 44px;
`

export const FormTitle = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  color: ${grayscale[500]};

  margin-bottom: 42px;
`

export const RadiusSelection = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  font-family: 'Inter';
  font-weight: 400;
  /* margin-bottom: 0px; */
  
  color: ${grayscale[500]};
  font-size: 18px;
  line-height: 22px;

  input {
    width: 20px;
    height: 18px;
    margin-right: 28px;
  }

`

export const TextAreaContainer = styled.div`
  padding-top: 16px;

  textarea {
    width: 100%;
    height: 120px;
    resize: none;
    /* Light/Neutral/Grayscale/100 */

    background: #F5F6FA;
    border-radius: 8px;
    padding: 16px;
    border: none;
    outline: none;
  }
`

export const TextAreaTitle = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};
`

export const SendButtonForm = styled.button`
  margin-top: 44px;
  width: 25%;
  height: 48px;
  border: none;
  outline: none;
  background: #FF7A00;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  transition: background 0.2s ease-in-out;

  &:disabled {
    background: #eee;
    color: #999;
  }
`
